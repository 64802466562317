import React, { cloneElement, forwardRef, useCallback, useMemo } from 'react';
import type { ReactElement, ReactNode, ElementType, RefObject } from 'react';
import { Virtuoso } from 'react-virtuoso';
import type { ListProps } from '@unifyapps/ui/_components/List';
import List from '@unifyapps/ui/_components/List';
import { Popover } from '@unifyapps/ui/components/Popover';
import { Box } from '@unifyapps/ui/components/Box';
import { useTranslation } from '@unifyapps/i18n/client';
import { useSlot } from '@unifyapps/ui/slots';
import type { SlotProps } from '@unifyapps/ui/slots/types';
import { ErrorPanel } from '../ErrorPanel';

const MAX_HEIGHT = 400;
const DEFAULT_ITEM_HEIGHT = 36;

const computeItemKey = (index: number, item: ReactElement) => item.key || index;
const itemContent = (_: number, item: React.ReactNode) => (
  <Box className="py-xxs">{cloneElement(item as ReactElement, { className: '!my-0' })}</Box>
);

interface ListBoxVirtuosoListProps {
  options: ReactElement[];
  itemHeight?: number;
  atBottomStateChange: (atBottom: boolean) => void;
}

function ListBoxVirtuosoList(props: ListBoxVirtuosoListProps) {
  const { options, itemHeight = DEFAULT_ITEM_HEIGHT, atBottomStateChange } = props;

  const style = useMemo(
    () => ({ height: Math.min(options.length * itemHeight, MAX_HEIGHT) }),
    [itemHeight, options.length],
  );

  return (
    <Virtuoso
      atBottomStateChange={atBottomStateChange}
      atBottomThreshold={200}
      computeItemKey={computeItemKey}
      data={options}
      fixedItemHeight={itemHeight}
      itemContent={itemContent}
      style={style}
    />
  );
}

const ListBox = forwardRef(
  (
    props: ListProps & {
      open: boolean;
      anchorEl: HTMLElement | null;
      footerDecoratorNode?: ReactNode;
      error?: Error | null;
      slots?: {
        listboxfooter?: ElementType;
      };
      slotProps?: {
        listboxfooter?: SlotProps<'div', object, object>;
      };
      fetchNextPage?: () => void;
      itemHeight?: number;
      disableVirtualization?: boolean;
    },
    ref: RefObject<HTMLUListElement>,
  ): ReactElement | null => {
    const { t } = useTranslation();
    const {
      open,
      slots,
      slotProps,
      anchorEl,
      error,
      fetchNextPage,
      disableVirtualization,
      itemHeight,
      ...rest
    } = props;

    const [ListBoxFooter, listBoxFooterProps] = useSlot('listboxfooter', {
      elementType: (() => null) as ElementType,
      externalForwardedProps: { slots, slotProps },
      className: props.children ? undefined : 'border-b-0 pb-0',
      ownerState: {},
    });

    const atBottomStateChange = useCallback(
      (atBottom: boolean) => {
        atBottom && fetchNextPage?.();
      },
      [fetchNextPage],
    );

    if (!open) {
      return null;
    }

    let contentEl;

    if (error) {
      contentEl = (
        <List onMouseDown={props.onMouseDown} ref={ref} style={props.style}>
          <Box className="mb-xl">
            <ErrorPanel
              errors={[
                {
                  title: t('common:ErrorFetchingResults'),
                  description:
                    (error.message as string | undefined) ?? t('common:DropdownTryAgain'),
                },
              ]}
            />
          </Box>
          <ListBoxFooter {...listBoxFooterProps} />
        </List>
      );
    } else if (disableVirtualization) {
      contentEl = (
        <List {...rest} className="max-h-[400px] overflow-auto rounded-md" ref={ref}>
          {props.children}
          <ListBoxFooter {...listBoxFooterProps} />
        </List>
      );
    } else {
      const options = Array.isArray(props.children) ? (props.children[0] as ReactElement[]) : [];

      contentEl = (
        // @ts-expect-error - using div as children are not li
        <List {...rest} className="rounded-md" component="div" ref={ref}>
          {options.length ? (
            <Box className="max-h-[400px] overflow-auto">
              <ListBoxVirtuosoList
                atBottomStateChange={atBottomStateChange}
                itemHeight={itemHeight}
                options={options}
              />
            </Box>
          ) : (
            props.children
          )}
          <ListBoxFooter {...listBoxFooterProps} />
        </List>
      );
    }

    return (
      <Popover anchorEl={anchorEl} open>
        <Popover.Content ignoreBoundary popupClassName="z-popup" useAnchorElWidth>
          {contentEl}
        </Popover.Content>
      </Popover>
    );
  },
);

ListBox.displayName = 'ListBox';

export const listBoxRenderer = ({
  slots,
  slotProps,
  fetchNextPage,
  error,
  disableVirtualization,
  itemHeight,
}: {
  slots?: {
    listboxfooter?: ElementType;
  };
  slotProps?: {
    listboxfooter?: SlotProps<'div', object, object>;
  };
  fetchNextPage?: () => void;
  error?: Error | null;
  disableVirtualization?: boolean;
  itemHeight?: number;
}) => {
  const ListBoxComponentWrapper = forwardRef(
    (
      listBoxProps: ListProps & { open: boolean; anchorEl: HTMLElement | null },
      ref: RefObject<HTMLUListElement>,
    ) => {
      return (
        <ListBox
          {...listBoxProps}
          disableVirtualization={disableVirtualization}
          error={error}
          fetchNextPage={fetchNextPage}
          itemHeight={itemHeight}
          ref={ref}
          slotProps={slotProps}
          slots={slots}
        />
      );
    },
  );

  ListBoxComponentWrapper.displayName = 'ListBoxComponentWrapper';

  return ListBoxComponentWrapper;
};

export type { ListProps };

export default ListBox;
