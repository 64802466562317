import { cva } from 'class-variance-authority';
import type { FeatureIconProps } from '@unifyapps/ui/components/FeaturedIcon';

export type InfoBoxVariant = 'error' | 'warning' | 'success' | 'brand' | 'white';

export const infoboxRootVariants = cva('flex flex-col rounded-md border', {
  variants: {
    variant: {
      error: 'border-error bg-error-primary',
      warning: 'border-warning bg-warning-primary',
      success: 'border-success bg-success-primary',
      brand: 'border-brand bg-brand-primary',
      white: 'border-disabled_subtle bg-primary',
    },
  },
});

export const infoboxTitleVariants = cva('text-md font-semibold', {
  variants: {
    variant: {
      error: 'text-error-primary',
      warning: 'text-secondary',
      success: 'text-success-primary',
      brand: 'text-secondary',
      white: 'text-secondary',
    },
  },
});

export const infoboxExpandButtonClassname = 'hover:!bg-transparent';

export const getFeatureIconColor = (variant: InfoBoxVariant): FeatureIconProps['color'] => {
  switch (variant) {
    case 'error':
      return 'error';
    case 'warning':
      return 'warning';
    case 'success':
      return 'success';
    case 'brand':
      return 'brand';
    case 'white':
      return 'gray';
  }
};
